<template xmlns:v-slot='http://www.w3.org/1999/XSL/Transform'>
  <v-container grid-list-xl>
    <v-layout row wrap>
      <!--      <v-flex sm12>-->
      <!--        <div class='title'>-->
      <!--          Appointment Details of patient:&nbsp;&nbsp;{{ appointment.PatientId }}-->
      <!--        </div>-->
      <!--      </v-flex>-->
      <v-flex sm12>
        <v-toolbar prominent>
          <v-btn color='primary' @click='goBack'>
            <v-icon class='iconText'>fa fa-angle-left</v-icon>
            Back
          </v-btn>
          <v-spacer></v-spacer>
          <div class='title'>
            Appointment Details of patient:&nbsp;&nbsp;{{
              appointment.PatientId
            }}
          </div>
        </v-toolbar>
      </v-flex>
      <v-flex xs12 sm12 lg12>
        <v-card class='elevation-0'>
          <v-card-title>
            <div class='headline font-weight-medium'>
              Examinations:
            </div>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field
              label='Height (cm):'
              v-model.number='examinations.Height'
            ></v-text-field>
            <v-text-field
              label='Weight (Kg):'
              v-model.number='examinations.Weight'
            ></v-text-field>
            <v-text-field
              label='BMI (Kg per meter square):'
              v-model.number='examinations.BMI'
            ></v-text-field>
            <v-text-field
              label='PEFR:'
              v-model.number='examinations.PEFR'
            ></v-text-field>
            <v-text-field
              label='Pre-Before Nebulisation:'
              v-model.number='examinations.PreNebulisation'
            ></v-text-field>
            <v-text-field
              label='Post-After Nebulisation:'
              v-model.number='examinations.PostNebulisation'
            ></v-text-field>
            <v-text-field
              label='Pulse Rate(Beats/Minute):'
              v-model.number='examinations.Pulse'
            ></v-text-field>
            <v-text-field
              label='Temperature (Fahrenheit):'
              v-model.number='examinations.Temperature'
            ></v-text-field>
            <v-text-field
              label='SPO2 (Pulse Ox meter in %):'
              v-model.number='examinations.SPO2'
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn color='primary' @click='saveData'>
              <v-icon class='iconText'>fa fa-save</v-icon>
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
      <v-flex xs12 sm12 lg12>
        <div class='elevation-1' style='background-color: #f5f5f5'>
          <v-expansion-panel>
            <v-expansion-panel-content>
              <template v-slot:header>
                <div class='subheading font-weight-medium'>
                  Allergy details:
                </div>
              </template>
              <div class='body-2 text-sm-center' v-if='emptyAllergy'>
                No answers submitted by patient
              </div>
              <!--<SmAllergyComp v-else :answer='allergy'></SmAllergyComp>-->
            </v-expansion-panel-content>
            <v-expansion-panel-content>
              <template v-slot:header>
                <div class='subheading font-weight-medium'>
                  Habits:
                </div>
              </template>
              <div class='body-2 text-sm-center' v-if='emptyHabits'>
                No answers submitted by patient
              </div>
              <!--<SmHabitsComp v-else :answer='habit'></SmHabitsComp>-->
            </v-expansion-panel-content>
          </v-expansion-panel>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import WebH from '../../api/web';
export default {
  props: ['appointment'],
  components: {},
  created() {
    this.api_getAllergy(this.appointment.Id);
    this.api_getHabit(this.appointment.Id);
    this.api_getExam(this.appointment.Id);
  },
  computed: {
    emptyAllergy() {
      return !this.allergy.Id;
    },
    emptyHabits() {
      return !this.habit.Id;
    }
  },
  data() {
    return {
      allergy: {},
      habit: {},
      examinations: {
        Height: 0,
        Weight: 0,
        BMI: 0,
        PEFR: 0,
        PreNebulisation: 0,
        PostNebulisation: 0,
        Pulse: 0,
        Temperature: 0,
        SPO2: 0
      }
    };
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    saveData() {
      this.examinations.AId = this.appointment.Id;
      this.examinations.PId = this.appointment.PatientPk;
      this.api_createExam(this.examinations);
    },
    async api_getAllergy(id) {
      this.allergy = {};
      let url = '/allergies/appt/' + id;
      const res = await WebH.Get(url, 'allergies');
      if (res.Success) {
        this.allergy = res.Data;
      }
    },
    async api_getHabit(id) {
      this.habit = {};
      let url = '/habits/appt/' + id;
      const res = await WebH.Get(url, 'habits');
      if (res.Success) {
        this.habit = res.Data;
      }
    },
    async api_getExam(id) {
      this.examinations = {
        Height: 0,
        Weight: 0,
        BMI: 0,
        PEFR: 0,
        PreNebulisation: 0,
        PostNebulisation: 0,
        Pulse: 0,
        Temperature: 0,
        SPO2: 0
      };
      let url = '/preexams/appt/' + id;
      const res = await WebH.Get(url, 'pre examinations');
      if (res.Success) {
        if (res.Data.Id) {
          this.examinations = res.Data;
        }
      }
    },
    async api_createExam(data) {
      const loader = this.$loading.show();
      const res = await WebH.Post('/preexams', data);
      loader.hide();
      if (res.Success) {
        await this.$notifyDlg.show('Examination details saved', 'Info', 'info');
      } else {
        await this.$notifyDlg.show(
          'You have already submitted it',
          'Info',
          'info'
        );
      }
    }
  }
};
</script>

<style scoped></style>
